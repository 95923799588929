@font-face {
  font-family: 'Bold';
  src: local('Bold'), url('./fonts/GraphikBold.otf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Light';
  src: local('Light'), url('./fonts/GraphikLight.otf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Medium';
  src: local('Medium'), url('./fonts/GraphikMedium.otf') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Light';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
